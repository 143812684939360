import purchaseReqVset from '../../purchaseRequisition/purchaseReqVset';
export default {
  name: 'buyerMasterEntryForm',
  props: ['rowData'],
  watch: {},
  components: { purchaseReqVset },
  data() {
    return {
      loader: false,
      buyMastEntryData: [
        {
          num: 1,
          buyer_master_id: 0,
          item_id: null,
          item: '',
          description: null,
          buyer_id: null,
          buyer_name: null,
          cost_center: null,
          location_id: null,
          location: null,
          region: null
        }
      ],
      buyMastEntryFields: [
        {
          key: 'add',
          stickyColumn: true,
          class: 'text-center'
        },
        {
          key: 'remove',
          stickyColumn: true,
          class: 'text-center'
        },
        {
          key: 'num',
          label: 'Num',
          class: 'text-center'
        },
        {
          key: 'item',
          label: 'Item',
          class: 'text-center'
        },
        {
          key: 'description',
          label: 'Description',
          class: 'text-center'
        },
        {
          key: 'buyer_name',
          label: 'Buyer Name',
          class: 'text-center'
        },
        {
          key: 'cost_center',
          label: 'Cost Center',
          class: 'text-center'
        },
        {
          key: 'location',
          label: 'Location',
          class: 'text-center'
        },
        {
          key: 'region',
          label: 'Region',
          class: 'text-center'
        }
      ],
      accessButtons: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      },
      unsubscribe: null,
      openModalFormType: 'buyerMasterEntryForm',
      openModalType: null,
      reqKeyFilter: {},
      sepctIndex: null,
      showBuyerMasterVsetModal: false,
      vsetName: null,
      editMode: false
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.addEditBuyMasDtls();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    if (!this.rowData) {
      this.editMode = true;
    } else {
      this.buyMastEntryFields.splice(0, 2);
      this.getBuyMasDataByRow(this.rowData);
    }
  },
  methods: {
    getBuyMasDataByRow(item) {
      this.buyMastEntryData = [];
      this.buyMastEntryData.push({
        num: 1,
        buyer_master_id: item.buyer_master_id,
        item: item.item_code,
        item_id: item.item_id,
        description: item.description,
        buyer_name: item.buyer_name,
        buyer_id: item.buyer_id,
        cost_center: item.cost_center,
        location: item.location,
        location_id: item.location_id,
        region: item.region
      });
    },
    mapSelectedBuyerVsetData(item) {
      if (item.name === 'buyMasInvItem') {
        this.buyMastEntryData[this.sepctIndex].item = item.item_code;
        this.buyMastEntryData[this.sepctIndex].item_id = item.item_id;
        this.buyMastEntryData[this.sepctIndex].description = item.description;
        this.buyMastEntryData[this.sepctIndex].cost_center = null;
        this.buyMastEntryData[this.sepctIndex].location = null;
        this.buyMastEntryData[this.sepctIndex].location_id = null;
        this.buyMastEntryData[this.sepctIndex].region = null;
        this.buyMastEntryData[this.sepctIndex].region_id = null;
      } else if (item.name === 'buyMasBuyer') {
        this.buyMastEntryData[this.sepctIndex].buyer_name = item.global_name;
        this.buyMastEntryData[this.sepctIndex].buyer_id = item.agent_id;
        this.buyMastEntryData = JSON.parse(
          JSON.stringify(this.buyMastEntryData)
        );
      } else if (item.name === 'buyMasCostCenter') {
        this.buyMastEntryData[this.sepctIndex].cost_center = item.flex_value;
        this.buyMastEntryData[this.sepctIndex].location = null;
        this.buyMastEntryData[this.sepctIndex].location_id = null;
        this.buyMastEntryData[this.sepctIndex].region = null;
        this.buyMastEntryData[this.sepctIndex].region_id = null;
        this.getLocationDtlsData(item);
        this.buyMastEntryData = JSON.parse(
          JSON.stringify(this.buyMastEntryData)
        );
      }
      this.showBuyerMasterVsetModal = false;
    },
    getLocationDtlsData(item) {
      const payload = {
        flex_value: item.flex_value
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getLocationDtlsData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.buyMastEntryData[this.sepctIndex].location =
              result[0].LOCATION_CODE;
            this.buyMastEntryData[this.sepctIndex].location_id =
              result[0].location_id;
            this.getRegionsDtlsData(result[0].location_id);
            this.buyMastEntryData = JSON.parse(
              JSON.stringify(this.buyMastEntryData)
            );
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getRegionsDtlsData(locId) {
      const payload = {
        location_id: locId
        // location_id: 370
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getRegionsDtlsData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.buyMastEntryData[this.sepctIndex].region = result[0].ZONE;
            this.buyMastEntryData = JSON.parse(
              JSON.stringify(this.buyMastEntryData)
            );
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditBuyMasDtls() {
      const filterMandFields = this.buyMastEntryData.filter(
        ele => !ele.buyer_name || !ele.cost_center || !ele.item
      );
      if (!filterMandFields.length) {
        const data = this.buyMastEntryData.map(ele => {
          return {
            buyer_master_id: ele.buyer_master_id ? ele.buyer_master_id : 0,
            item_id: ele.item_id,
            item_code: ele.item,
            buyer_id: ele.buyer_id,
            buyer_name: ele.buyer_name,
            cost_center: ele.cost_center,
            description: ele.description,
            location_id: ele.location_id,
            location: ele.location,
            region: ele.region
          };
        });
        const payload = {
          inputParamters: {
            item_buyer_assignment_details: data
          }
        };
        this.loader = true;
        this.$store
          .dispatch('purchaseRequisition/addEditBuyMasDtls', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.editMode = false;
              const result = response.data.data;
              this.autoFillData(result);
              this.$bvToast.toast(response.data.message, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });
            } else {
              this.editMode = true;
              this.$bvToast.toast(response.response.data.message, {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        this.$bvToast.toast('Kindly fill all the mandatory fields.', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    autoFillData(respData) {
      let x = 1;
      const data = respData.map(ele => {
        return {
          buyer_master_id: ele.buyer_master_id,
          item_id: ele.item_id,
          item: ele.item_code,
          buyer_id: ele.buyer_id,
          buyer_name: ele.buyer_name,
          cost_center: ele.cost_center,
          description: ele.description,
          region: ele.region,
          location_id: ele.location_id,
          location: ele.location,
          num: x++
        };
      });
      this.buyMastEntryData = data;
    },
    openVsetModal(type, index) {
      this.sepctIndex = index;
      if (type === 'buyer') {
        this.vsetName = 'Buyer Details';
        this.openModalType = 'buyer';
        this.showHideBuyerMasterVsetModal(true);
      } else if (type === 'invItem') {
        this.vsetName = 'Inventory Item Details';
        this.openModalType = 'invItem';
        this.showHideBuyerMasterVsetModal(true);
      } else if (type === 'costCenter') {
        if (this.buyMastEntryData[index].item) {
          this.vsetName = 'Cost Center Details';
          this.openModalType = 'costCenter';
          this.showHideBuyerMasterVsetModal(true);
        } else {
          this.$bvToast.toast('Please select item first', {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
        }
      } else if (type === 'location') {
        if (this.buyMastEntryData[index].cost_center) {
          this.vsetName = 'Location Details';
          this.openModalType = 'location';
          this.reqKeyFilter.cost_center = this.buyMastEntryData[
            index
          ].cost_center;
          this.showHidePurReqVsetModal(true);
        } else {
          this.$bvToast.toast('Please select cost center first', {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
        }
      }
    },
    showHideBuyerMasterVsetModal(flag) {
      this.showBuyerMasterVsetModal = flag;
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'buyer') {
        this.buyMastEntryData[index].buyer_name = null;
        this.buyMastEntryData[index].buyer_id = null;
      } else if (vsetCode === 'costCenter') {
        this.buyMastEntryData[index].cost_center = null;
        this.buyMastEntryData[index].location = null;
        this.buyMastEntryData[index].location_id = null;
        this.buyMastEntryData[index].region = null;
        this.buyMastEntryData[index].region_id = null;
      } else if (vsetCode === 'location') {
        this.buyMastEntryData[index].location = null;
        this.buyMastEntryData[index].location_id = null;
        this.buyMastEntryData[index].region = null;
        this.buyMastEntryData[index].region_id = null;
      } else if (vsetCode === 'invItem') {
        this.buyMastEntryData[index].item = '';
        this.buyMastEntryData[index].item_id = null;
        this.buyMastEntryData[index].description = null;
        this.buyMastEntryData[index].cost_center = null;
        this.buyMastEntryData[index].location = null;
        this.buyMastEntryData[index].location_id = null;
        this.buyMastEntryData[index].region = null;
        this.buyMastEntryData[index].region_id = null;
      }
    },
    addNewRow() {
      this.buyMastEntryData.push({
        num: this.buyMastEntryData.length + 1,
        buyer_master_id: 0,
        item: '',
        item_id: null,
        description: null,
        buyer_name: null,
        buyer_id: null,
        cost_center: null,
        location: null,
        location_id: null,
        region: null
      });
    },
    removeRow(index) {
      this.buyMastEntryData.splice(index, 1);
      this.buyMastEntryData.map((ele, index) => {
        ele.num = index + 1;
      });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
